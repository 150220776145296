import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { delay } from '../helpers';
import '../styles.css';

// Transaction "Comprar" and "Vender" menu
const Timer = ({isLoading, fetch, countdown, setCountdown, PRICE_INTERVAL = 10, isSending = false}) => {
    
     // Mover la lógica de actualización de clase al useEffect
     useEffect(() => {
        const button = document.querySelector('#buy-sell-button');
        let timeoutId;
        if (button && isLoading) {
            // Limpiar cualquier timeout pendiente
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            button.classList.remove('c-buy-sell-button-container-enabled');
            button.classList.add('c-buy-sell-button-container');
        } else if (button && !isLoading) {
            timeoutId = setTimeout(() => {
                if (button) {  // Verificar que el botón aún existe
                    button.classList.remove('c-buy-sell-button-container');
                    button.classList.add('c-buy-sell-button-container-enabled');
                }
            }, 1000);
        }
        // Función de limpieza
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isLoading]); // Se ejecutará cuando isLoading cambie

    const handleFetch = useCallback(() => {
        fetch();
    }, [fetch]);


    // Countdown interval
    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    handleFetch();
                }
                return prevCountdown === 1 ? PRICE_INTERVAL : prevCountdown - 1;
            });
        }, 1000);
        
        return () => clearInterval(countdownInterval);
    }, [countdown]); 

    if( isSending ){
        return (<></>);
    }

    if( !isLoading ){
        return (
            <div className='c-countdown-container'>
                Nuevo precio en {countdown}
            </div>
        )
    }else{
        return (
            <div className='c-countdown-container-loading'>
                <FontAwesomeIcon icon={faSpinner} spin 
                    className='c-countdown-container-loading-icon'
                />
                Obteniendo precios
            </div>
        )
    }
}

export default Timer;